<template>
  <v-app id="inspire">
    <v-main>
      <div class="authentication-page">
        <v-row no-gutters style="height: 100vh">
          <v-col sm="12" lg="6" align="center" justify="center">
            <v-row
              class="d-flex justify-center align-center"
              style="height: 100vh"
            >
              <v-col sm="11" md="6" lg="6">
                <v-card style="background-color: rgba(179, 170, 170, 0.4)">
                  <v-toolbar color="#0D3C74" flat>
                    <v-toolbar-title class="font-weight-bold text-left"
                      >Reset Password</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-card-text>
                    <v-form
                      validate-on="submit lazy"
                      @submit.prevent="sendPasswordResetConfirmRequest"
                    >
                      <v-card-text>
                        <v-text-field
                          type="password"
                          v-model="user.password"
                          :rules="[this.formNotEmptyRule]"
                          label="Password"
                          required
                        />
                        <v-text-field
                          type="password"
                          v-model="user.password2"
                          :rules="[this.formNotEmptyRule]"
                          label="Repeat Password"
                          required
                        />
                        <v-alert
                          v-if="Object.keys(form_message).length > 0"
                          type="warning"
                          title="Fix your inputs"
                          variant="tonal"
                          close-label="Close Alert"
                          color="deep-purple-accent-4"
                        >
                          <div
                            v-for="(value, key) in form_message"
                            :key="key"
                            class="text-left"
                          >
                            {{ key }}: {{ value.join(" ") }}
                          </div>
                        </v-alert>
                      </v-card-text>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-btn
                              color="success"
                              type="submit"
                              block
                              :loading="password_reset_confirm_button_loading"
                              >Reset Password</v-btn
                            >
                            <v-btn
                              variant="text"
                              color="grey-lighten-3"
                              block
                              v-on:click.prevent="goToLogin"
                              >Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-form>
                  </v-card-text>
                  <!-- <v-card-actions> -->
                  <v-spacer />

                  <!-- </v-card-subtitle> -->

                  <!-- </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-none d-md-flex position-relative overflow-hidden"
          >
            <div class="scrolling-image"></div>
            <div
              class="slogan-overlay d-flex flex-column justify-center align-center"
            >
              <div class="text-h1 font-weight-bold white--text text-center">
                Shimmers
              </div>
              <div class="text-h4 white--text text-center mt-4">
                Experience The Future Of Fashion Photography
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </div>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import FormMixin from "@/mixins/FormMixin";
import axios from "axios";
// import MessageShow from "./MessageShow";

export default {
  name: "login-page",
  data() {
    return {
      user: { password: "", password2: "" },
      form_message: [],
      password_reset_confirm_button_loading: false,
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin, FormMixin],
  created() {
    // console.log(this.formNotEmptyRule(null));
    console.log(this.isAuthenticated());
    if (this.isAuthenticated()) {
      this.$router.push("/home");
    }
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    sendPasswordResetConfirmRequest: function () {
      this.password_reset_confirm_button_loading = true;
      let data = JSON.stringify({
        uid: this.$route.params.uidb64,
        token: this.$route.params.token,
        new_password1: this.user.password,
        new_password2: this.user.password2,
      });
      console.log(data);
      axios
        .post(this.api.user.password_reset_confirm, data)
        .then((response) => {
          console.log("response recieved");
          console.log(response);

          this.goToLogin();
        })
        .catch((error) => {
          this.form_message = error.response.data;
          console.log(error);
          console.log("error happended");
        })
        .finally(() => {
          this.password_reset_confirm_button_loading = false;
        });
    },
  },
};
</script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
  