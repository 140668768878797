<template>
  <v-app id="inspire">
    <v-main> <MessageCard :title="title" :messages="messages" /> </v-main>
  </v-app>
</template>
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";
import MessageCard from "@/components/MessageCard";

export default {
  name: "PasswordResetSuccessMessage",
  data() {
    return {
      title: "Password Reset Success",
      messages: [
        "Your password has been reset successfully.",
        "Please check your email for password reset link.",
      ],
      icon: "mdi-check-circle",
      iconColor: "green",
    };
  },
  components: {
    MessageCard,
  },
  mixins: [AuthMixins, APIMixin],
  created() {},

  methods: {},
};
</script>
<style scoped>
</style>
