<template>
  <div class="message-card">
    <v-container class="fill-height authentication-page" fluid>
      <v-row align="center" justify="center">
        <v-col sm="9" lg="4" align="center" justify="center">
          <v-card style="background-color: rgba(179, 170, 170, 0.9)">
            <v-card-title block color="blue-lighten-5">{{
              title
            }}</v-card-title>
            <v-card-item v-if="icon">
              <v-icon :icon="icon" size="64" :color="iconColor"></v-icon>
            </v-card-item>

            <v-card-text v-for="message in messages" :key="message">
              <p>{{ message }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MessageCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    messages: {
      type: Array,
      required: true,
      validator: (value) => value.every((item) => typeof item === "string"),
    },
  },
};
</script>

<style scoped>
.message-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.message-card p {
  text-align: center;
  font-size: 1.2em;
}
</style>
