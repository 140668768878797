<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height authentication-page" fluid>
        <v-row align="center" justify="center">
          <v-col sm="9" lg="4" align="center" justify="center">
            <v-card style="background-color: rgba(179, 170, 170, 0.9)">
              <!-- <v-toolbar color="blue-lighten-5" flat> -->
              <v-card-title color="blue-lighten-5" block
                >Registration Success</v-card-title
              >
              <!-- </v-toolbar> -->
              <v-card-text>
                <p>You successfuly registered your account.</p>
                <p>Please activate your email to continue.</p>
                <p>Check your E-mail inbox for the verification email.</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  <script>
/* eslint-disable no-console */

import AuthMixins from "@/mixins/authenticationMixin";
import APIMixin from "@/mixins/APIMixin";

export default {
  name: "login-page",
  data() {
    return {
      user: { username: "", password: "", password2: "" },
    };
  },
  components: {},

  mixins: [AuthMixins, APIMixin],
  created() {
    console.log(this.isAuthenticated());
    if (this.isAuthenticated()) {
      this.$router.push("/home");
    }
  },

  methods: {},
};
</script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
  